<script setup></script>
<template>
  <div class="label-el">
    <slot></slot>
  </div>
</template>

<style scoped>
.label-el {
  position: relative;
  left: 20px;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  pointer-events: none;
  position: absolute;
  transition: all 100ms ease-out, color 0.15s ease-out;
  font-size: 16px;
}

.label-el.active {
  top: 0%;
  transform: translate3d(0, -50%, 0);
  position: absolute;

  background-color: var(--forms-label-bg, #fff);
  padding: 0 0.55rem;
  font-size: 13px;
  font-weight: 400;
  border-radius: 20px;

  white-space: nowrap;
  pointer-events: none;
  user-select: none;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.label-el.error {
  color: rgb(255, 104, 28);
}
</style>
